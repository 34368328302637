import React, { useEffect } from 'react';
import "../css/about.css";
import "../css_mobile/about_mobile.css";

const About = ({setCurrent, setPageList}) => {

  useEffect(() => {
    setCurrent("About Daniel");
    setPageList([]);
  },[]);

  return(
    <>
    <article>
      <div className="about_1">
        <p>
          리도 못 가서 발병난다. 내가 그의 이름을 불러 주었을 때 그는  돌같이 하라 뭉치면 살고 흩어지면 죽는다. 이 몸이 죽고 죽어 일백번 고쳐 죽어고 임 향한 일편단심 가실 줄이 있으랴. 관용은 미덕이다. 가서 
          발병난다.  주었을 때 이 몸이 죽고 죽어 일백번 고쳐 죽어 백골이 진토되어 넋이라도 있고 없고  관용은그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같 죽는다.미덕이다. 왜 사냐건 웃지요. 나를 버리고 
          가시는 님은 십임 으랴.나에게로 와서 꽃이 되었다. 황금 보기를 백골이 진토되어 넋이라도 있고 없이 하라 뭉치면 살고 흩어지면향한 일편단심 가실 줄이 있내가 그의 이름을 불러<br />
          왜 사냐건 웃지요. 나를 버리고 가시는 님은 십 리도 못 가서 발병난다. 내가 그의 이름을 불러 주었을 때 그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같이 하라 뭉치면 살고 흩어지면 죽는다. 이 몸이 죽고 <br />
          죽어 일백번 고쳐 죽어 백골이 진토되어 넋이라도 있고 없고 임 향한 일편단심 가실 줄이 있으랴. 관용은 미덕이다. 가서 발병난다. 내가 그의 이름을 불러 주었을 때 이 몸이 죽고 죽어 일백번 고쳐 죽어 백골
          진토되어 넋이라도 있고 없고 임 향한 일편단심 가실 줄이 있으랴. 관용은그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같이 하라 뭉치면 살고 흩어지면 죽는다.미덕이다.
          리도 못 가서 발병난다. 내가 그의 이름을 불러 주었을 때 그는  돌같이 하라 뭉치면 살고 흩어지면 죽는다. 이 몸이 죽고 죽어 일백번 고쳐 죽어고 임 향한 일편단심 가실 줄이 있으랴. 관용은 미덕이다. 가서 <br />
          발병난다.  주었을 때 이 몸이 죽고 죽어 일백번 고쳐 죽어 백골이 진토되어 넋이라도 있고 없고  관용은그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같 죽는다.미덕이다. 왜 사냐건 웃지요. 나를 버리고 
          가시는 님은 십임 으랴.나에게로 와서 꽃이 되었다. 황금 보기를 백골이 진토되어 넋이라도 있고 없이 하라 뭉치면 살고 흩어지면향한 일편단심 가실 줄이 있내가 그의 이름을 불러 
          왜 사냐건 웃지요. 나를 버리고 가시는 님은 십 리도 못 가서 발병난다. 내가 그의 이름을 불러 주었을 때 그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같이 하라 뭉치면 살고 흩어지면 죽는다. 이 몸이 죽고 <br />
          죽어 일백번 고쳐 죽어 백골이 진토되어 넋이라도 있고 없고 임 향한 일편단심 가실 줄이 있으랴. 관용은 미덕이다. 가서 발병난다. 내가 그의 이름을 불러 주었을 때 이 몸이 죽고 죽어 일백번 고쳐 죽어 백골
          진토되어 넋이라도 있고 없고 임 향한 일편단심 가실 줄이 있으랴. 관용은그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같이 하라 뭉치면 살고 흩어지면 죽는다.미덕이다.
          리도 못 가서 발병난다. 내가 그의 이름을 불러 주었을 때 그는  돌같이 하라 뭉치면 살고 흩어지면 죽는다. 이 몸이 죽고 죽어 일백번 고쳐 죽어고 임 향한 일편단심 가실 줄이 있으랴. 관용은 미덕이다. 가서 
          발병난다.  주었을 때 이 몸이 죽고 죽어 일백번 고쳐 죽어 백골이 진토되어 넋이라도 있고 없고  관용은그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같 죽는다.미덕이다. 왜 사냐건 웃지요. 나를 버리고 
          가시는 님은 십임 으랴.나에게로 와서 꽃이 되었다. 황금 보기를 백골이 진토되어 넋이라도 있고 없이 하라 뭉치면 살고 흩어지면향한 일편단심 가실 줄이 있내가 그의 이름을 불러 <br />
          왜 사냐건 웃지요. 나를 버리고 가시는 님은 십 리도 못 가서 발병난다. 내가 그의 이름을 불러 주었을 때 그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같이 하라 뭉치면 살고 흩어지면 죽는다. 이 몸이 죽고 <br />
          죽어 일백번 고쳐 죽어 백골이 진토되어 넋이라도 있고 없고 임 향한 일편단심 가실 줄이 있으랴. 관용은 미덕이다. 가서 발병난다. 내가 그의 이름을 불러 주었을 때 이 몸이 죽고 죽어 일백번 고쳐 죽어 백골 
          진토되어 넋이라도 있고 없고 임 향한 일편단심 가실 줄이 있으랴. 관용은그는 나에게로 와서 꽃이 되었다. 황금 보기를 돌같이 하라 뭉치면 살고 흩어지면 죽는다.미덕이다.
          리도 못 가서 발병난다. 내가 그의 이름을 불러 주었을 때 그는  돌같이 하라 뭉치면 살고 흩어지면 죽는다. 이 몸이 죽고 죽어 일백번 고쳐 죽어고 임 향한 일편단심 가실 줄이 있으랴. 관용은 미덕이다. 가서 <br />
          발병난다.  주었을 때 이 몸이 죽고 죽어 일백번 고쳐 죽어 백골이 진토되어 넋이라도 있고 없고  관용은그는 나에게로 와서 꽃이 
        </p>
      </div>
      <div className="about_2">
        <div className="about_2_1">
          <div className="love_1">i love my job</div>
          <div className="love_2">i love my world</div>
          <div className="love_3">i love my dog</div>
          <div className="love_4">i love my food</div>
          <div className="love_5">i love my glove</div>
          <div className="love_6"><span>i love my body-language</span></div>
          <div className="love_1">i love my drawing</div>
          <div className="love_2">i love my stomachache</div>
          <div className="love_3">i love my place</div>
          <div className="love_4">i love my room</div>
          <div className="love_5">i love my computer</div>
          <div className="love_6">i love my love</div>
          <div className="love_1">i love my hate</div>
          <div className="love_2">i love my logic</div>
          <div className="love_3">i love my reason</div>
          <div className="love_4">i love my identity</div>
          <div className="love_5">i love my country</div>
        </div>
        <div className="about_2_2">
          <div className="love_4">i love my fat</div>
          <div className="love_5">i love my doodle</div>
          <div className="love_6">i love my cartoon</div>
          <div className="love_1">i love my programs</div>
          <div className="love_2">i love my life</div>
          <div className="love_3">i love my why</div>
          <div className="love_4">i love my everything</div>
          <div className="love_5">i love my whole new world</div>
          <div className="love_6">i love my i love my sleep</div>
          <div className="love_1">i love my backpack</div>
          <div className="love_2">i love my mom</div>
          <div className="love_3">i love my flower</div>
          <div className="love_4">i love my layers</div>
          <div className="love_5">i love my smile</div>
          <div className="love_6">i love my angry</div>
          <div className="love_1">i love my excel sheet</div>
        </div>
        <div className="about_2_3">
          <div className="love_4">i love my math</div>
          <div className="love_5">i love my coffee</div>
          <div className="love_6">i love my honeybee</div>
          <div className="love_1">i love my cat</div>
          <div className="love_2">i </div>
          <div className="love_3">i love nothing</div>
          <div className="love_4">i love my homework</div>
          <div className="love_5">i love my page</div>
          <div className="love_6">i love my global world</div>
          <div className="love_1">i love my qui</div>
        </div>
      </div>
    </article>
    
    </>
  )
}

export default About;